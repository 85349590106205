import { API_CASSA, API_CASSA_DEV_DEMO } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

export const getAllCountryProfileWithRisk = () => {
  return AjaxService.get(
    API_CASSA + "/api/countryprofile/getAllCountryProfileWithRisk"
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getStudyByCountry = (_queryParam) => {
  return AjaxService.get(
    API_CASSA +
    `/api/countryprofile/getStudyDetailsByCountry?country=${_queryParam}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getCountryDetailsAtt = (_queryParam) => {
  return AjaxService.get(
    API_CASSA + `/api/countryprofile/getRiskByCountry?id=${_queryParam}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//getAllDepotRiskProfile
export const getAllDepotRiskProfile = () => {
  return AjaxService.get(
    API_CASSA + "/api/depotprofile/getAllDepotDetailsForDigitalTwin"
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotDetailsAtt = (_queryParam) => {
  return AjaxService.get(
    API_CASSA + `/api/depotprofile/getDepotDetailsById?id=${_queryParam}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotWithCountry = (_queryParam) => {
  return AjaxService.get(
    API_CASSA + `/api/depotprofile/getAllDepotsByCountry?country=${_queryParam}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotinventoryDetails = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getDepotinventoryDetailsForDigitalTwinByMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotInventoryCount = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getDepotinventoryDetailsForDigitalTwinByMultipleDepotsCount`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getShipmentDetailsBySource = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getShipmentDetailsBySource?source=${_queryParam.source}&size=${_queryParam.size}&page=${_queryParam.page}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getFirmDetailsBySource = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getFirmOrderByDepotAndStudy?source=${_queryParam.source}&size=${_queryParam.size}&page=${_queryParam.page}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getPlannedOrderBySource = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getPlannedOrderByDepotAndStudy?source=${_queryParam.source}&size=${_queryParam.size}&page=${_queryParam.page}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotToDepotInTransitBySource = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getDepotToDepotInTransit?source=${_queryParam.source}&size=${_queryParam.size}&page=${_queryParam.page}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getShipmentAndDepotInTransitBySource = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getShipmentAndDepotInTransitWithMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getShipmentDetailsForDownload = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getShipmentDetailsForDownload?source=${_queryParam.source}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//getShipmentDetailsCount
export const getShipmentDetailsCount = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getTotalCount?source=${_queryParam.source}`,
    JSON.stringify(_queryParam.studyIds)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotInventories = (_queryParam) => {
  return AjaxService.get(API_CASSA + `/api/material/getDepotInventories`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};


//get All Sites
export const getAllSiteProfiles = () => {
  return AjaxService.get(
    API_CASSA + "/api/siteprofile/getAllSiteProfiles"
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//multiple country apis ============
export const getStudyDetailsByMultipleCountry = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/countryprofile/getStudyDetailsByMultipleCountry`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getRiskByMultipleCountry = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/countryprofile/getRiskByMultipleCountry`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotWithMultiplesCountry = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getAllDepotsByMultipleCountriesAndStudies`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};
//multiple country apis ============

export const getMultipleDepotDetailsById = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getMultipleDepotDetailsById`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotShipmentCount = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getShipmentAndDepotInTransitCountWithMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDepotPlanAndFirmCount = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/depotprofile/getPlanAndFirmOrderCountWithMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getFailedorderDepotsApi = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/charts/getAllDepotsForDigitalTwinFromAlerts?alertName=${_queryParam}`,
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getShipmentForAlertsCount = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +    
    `/api/depotprofile/getShipmentForAlertsCountWithMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getShipmentForAlertsData = (_queryParam) => {
  return AjaxService.post(
    API_CASSA +
    `/api/depotprofile/getShipmentForAlertsWithMultipleDepots`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProductFailedOrderDepots = (_queryParam) => {
  return AjaxService.post(
    API_CASSA + `/api/charts/getAllDepotsForDigitalTwinFromProductRiskAlerts?alertName=${_queryParam}`,
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};